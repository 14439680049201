import styled from 'styled-components';

export const Container = styled.div.attrs({
  className: 'container',
})`
  .MuiLinearProgress-colorPrimary {
    background-color: #fff !important;
  }
  .MuiLinearProgress-barColorPrimary {
    background-color: #c2a0f8 !important;
  }
  .containerMaterial {
    min-width: fit-content;
    width: auto;
    padding: 0px 4px;
    order: 1;
    margin: 0px 64px 2px 0px;
  }
  .linkMaterial {
    font-size: 14px;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ContainerLoader = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const Footer = styled.div`
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  input {
    border: 1px solid #dfe1e6;
    padding: 5px;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    border-radius: 3px;
  }
`;
