import React, { useState, useRef, useCallback, useEffect } from 'react';
import { Controller } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { Content, Option } from './styles';
import InfiniteScroll from 'react-infinite-scroll-component';
import { InputMultiSelectProps, SelectType } from './protocols';
/**
 * @description Input select com multipla seleção de opções.
 */
const MultiSelect: React.FC<InputMultiSelectProps> = ({
  label,
  name,
  register,
  control,
  setValue,
  changeSelected,
  options,
  placeholder = '',
  labelTodasAsOpcoes = 'Todos',
  resetCampos,
}) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
  const [isOpen, setIsOpen] = useState(false);
  const [filter, setFilter] = useState('');
  const ITEM_TO_RENDER = 50;
  const wrapperRef = useRef<HTMLUListElement | null>(null);

  const [renderItem, setRenderItem] = useState(ITEM_TO_RENDER);
  const handleInputClick = useCallback(() => setIsOpen(!isOpen), [isOpen]);
  const handleInputChange = useCallback((event) => {
    setFilter(event.target.value);
  }, []);

  const loadMore = useCallback(
    () => setRenderItem((prev) => prev + ITEM_TO_RENDER),
    [],
  );

  const handleOptionClick = (option: SelectType) => {
    setSelectedOptions((prev) => {
      let newSelectedOptions;
      if (prev.some((selected) => selected.value === option.value)) {
        newSelectedOptions = prev.filter(
          (selected) => selected.value !== option.value,
        );
      } else {
        newSelectedOptions = [...prev, option];
      }

      changeSelected(newSelectedOptions);
      return newSelectedOptions;
    });
  };
  const handleSelectAll = () => {
    if (selectedOptions.length === options.length) {
      setSelectedOptions([]);
      changeSelected([]);
    } else {
      setIsOpen(false);
      changeSelected(options);
      setSelectedOptions(options);
    }
  };

  useEffect(() => {
    if (setValue) {
      setValue(name, selectedOptions);
    }
  }, [selectedOptions, setValue, name]);

  const getDisplayValue = () => {
    if (options.length > 0) {
      const labels = selectedOptions.map((option) => option.label).join(', ');
      const maxLength = 36;
      if (selectedOptions.length === options.length) {
        return 'Todos';
      }
      if (labels.length > maxLength) {
        return `${labels.substring(0, maxLength)}...`;
      }
      return labels;
    }
  };

  function useCloseOptions(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setFilter('');
          setIsOpen(false);
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }
  useCloseOptions(wrapperRef);
  useEffect(() => {
    if (resetCampos) {
      setSelectedOptions([]);
      changeSelected([]);
    }
  }, [resetCampos]);
  return (
    <Form.Group>
      {label && <Form.Label>{label}</Form.Label>}
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            <input
              {...register(name)}
              type="text"
              className="form-control"
              placeholder={placeholder}
              onClick={handleInputClick}
              onChange={handleInputChange}
              ref={inputRef}
              value={filter || getDisplayValue()}
              readOnly
              style={{
                fontSize: '0.875rem',
              }}
            />
            {isOpen && (
              <Content>
                <ul className="dropdown-menu show" ref={wrapperRef}>
                  <InfiniteScroll
                    dataLength={renderItem}
                    next={loadMore}
                    hasMore={renderItem < options.length}
                    loader={<small>Carregando...</small>}
                  >
                    {options.length > 0 && (
                      <Option
                        className={`dropdown-item ${
                          selectedOptions.length === options.length
                            ? 'selected'
                            : ''
                        }`}
                        onClick={handleSelectAll}
                        style={{
                          fontSize: '0.875rem',
                        }}
                      >
                        <input
                          type="checkbox"
                          checked={selectedOptions.length === options.length}
                          onChange={handleSelectAll}
                        />
                        {labelTodasAsOpcoes}
                      </Option>
                    )}
                    {options
                      .filter((option: SelectType) =>
                        option.label
                          .toLowerCase()
                          .includes(filter.toLowerCase()),
                      )
                      .map((option: SelectType) => (
                        <Option
                          key={option.value}
                          title={option.label}
                          className={`dropdown-item ${
                            selectedOptions.some(
                              (selected) => selected.value === option.value,
                            )
                              ? 'selected'
                              : ''
                          }`}
                          onClick={() => {
                            handleOptionClick(option);
                            if (inputRef)
                              inputRef.current!.value = option.label;
                            setFilter('');
                          }}
                          style={{
                            fontSize: '0.875rem',
                          }}
                        >
                          <input
                            type="checkbox"
                            checked={selectedOptions.some(
                              (selected) => selected.value === option.value,
                            )}
                            onChange={() => handleOptionClick(option)}
                            onClick={(e) => e.stopPropagation()}
                          />
                          {option.label}
                        </Option>
                      ))}
                  </InfiniteScroll>
                </ul>
              </Content>
            )}
          </>
        )}
      />
    </Form.Group>
  );
};

export default MultiSelect;
