/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal, {
  ModalBody,
  ModalHeader,
  ModalTitle,
} from '@atlaskit/modal-dialog';
import React, { useContext, useEffect, useState } from 'react';
import { ClipLoader } from 'react-spinners';
import { toast } from 'react-toastify';
import { ThemeContext } from 'styled-components';

import api from '~/services/api';
import { getUserData } from '~/services/user';
import { ConfirmButton } from '../Buttons';
import { ListBox } from '../ListBox';

import { Container, ContainerLoader, Footer } from './styles';
import { IModulos, IOrdemModuloProps } from './types';

const OrderModulo: React.FC<IOrdemModuloProps> = ({ setOpenOrderModal }) => {
  const { colors } = useContext(ThemeContext);
  const [optionsBase, setOptionsBase] = useState<any[]>([]);
  const [options, setOptions] = useState<any[]>([]);
  const [loadModal, setLoadModal] = useState<boolean>(true);
  const user = getUserData();

  useEffect(() => {
    const getModulos = async () => {
      const { data } = await api.get(`/telasModulo/${user?.cod_controle}`);
      if (data.success) {
        const option = data.data.map((modulo: IModulos) => ({
          cod_campo: modulo.cod_modulo,
          des_campo: modulo.des_modulo,
          flg_filtro: modulo.flg_inativo,
          flg_filtro_obrigatorio: modulo.flg_requer_superadmin,
          num_ordem: modulo.num_ordem_menu,
        }));

        const dataMap = option.map((item: any, _index: number) => ({
          id: _index,
          order: _index + 1,
          cod_campo: item.cod_campo,
          des_campo: item.des_campo,
          flg_filtro: item.flg_inativo,
          flg_filtro_obrigatorio: item.flg_requer_superadmin,
          num_ordem: item.num_ordem_menu,
        }));

        setOptions(dataMap);
        setOptionsBase(dataMap);
        setLoadModal(false);
      } else {
        setLoadModal(false);
      }
    };
    getModulos();
  }, []);

  const handleSaveOrderModulo = async () => {
    const verificaAlteracao = options.filter(function (
      elem: any,
      pos: any,
      arr: any,
    ) {
      return optionsBase.indexOf(elem) === pos;
    });

    if (verificaAlteracao.length === 0) {
      const { data } = await api.put(`/modulo`, {
        options,
      });

      if (data.success) {
        toast.success(data.message);
        setOpenOrderModal(false);
      } else {
        toast.warning(data.message);
      }
    } else {
      setOpenOrderModal(false);
    }
  };

  const handleCloseModal = () => {
    return setOpenOrderModal(false);
  };
  const Loading: React.FC = () => {
    return (
      <ContainerLoader>
        <ClipLoader color={colors.primary} />
      </ContainerLoader>
    );
  };

  return (
    <>
      <Container>
        <Modal width="large" onClose={handleCloseModal}>
          <ModalHeader
            style={{
              paddingBottom: '0.5rem',
            }}
          >
            <ModalTitle>Ordenação de Módulos</ModalTitle>
          </ModalHeader>
          <ModalBody style={{ height: '43.125rem' }}>
            {loadModal ? (
              <Loading />
            ) : (
              <Container>
                <ListBox
                  optionSelected={options}
                  setOptionSelected={setOptions}
                />
                <Footer>
                  <ConfirmButton
                    onClick={() => setOpenOrderModal(false)}
                    style={{
                      color: '#ffffff',
                      background: '#dc3545',
                      height: '100%',
                      maxHeight: '42px',
                    }}
                  >
                    CANCELAR
                  </ConfirmButton>
                  <ConfirmButton
                    onClick={handleSaveOrderModulo}
                    style={{
                      color: '#ffffff',
                      background: '#28a745',
                      height: '100%',
                      maxHeight: '42px',
                    }}
                  >
                    SALVAR
                  </ConfirmButton>
                </Footer>
              </Container>
            )}
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
};

export default OrderModulo;
