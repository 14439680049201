import styled from 'styled-components';

export const InputContainer = styled.div`
  .form-control {
    font-size: 14px;
  }
  .align-right {
    text-align: right;
  }
  .form-control:disabled,
  .form-control[readonly] {
    font-size: 14px;
    color: #a5adba;
    background-color: #e9ecef;
    border: 0;
    cursor: not-allowed;
  }

  .form-group {
    margin-top: 0.5rem;
  }

  .ButtonWindow {
    position: absolute;
    background: #f0f2f5;
    width: 40px;
    height: 100%;
    font-size: 12pt;
    right: 0px;
    bottom: 0;
    border-radius: 2px;
    // border: none;
    color: #007bff;
  }

  .form-control.is-invalid {
    background-image: none;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    padding-right: 5px;
    border-bottom: 2px solid #cf1919;
    &:focus {
      box-shadow: none;
    }
  }

  .form-label {
    font-size: 0.8571428571428571em;
    font-style: inherit;
    color: var(--ds-text-lowEmphasis, var(--ds-text-lowEmphasis, #6b778c));
    font-weight: 600;
    line-height: 1.3333333333333333;
    display: inline-block;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    margin-bottom: 4px;
  }

  .color-white {
    color: white;
  }
  .spacing {
    display: flex;
    gap: 0.5rem;
    margin: 0.5rem;
  }
  .button-input {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: 0;
    right: 0;
    margin-right: 1px;
    height: 100%;
    width: 40px;
    background: #8850bf;
    border-radius: 0px 4px 4px 0px;
    &:hover {
      background: #57069e;
    }
    &:focus {
      background: #57069e;
    }
  }
  input {
    height: 41px;
    border: 2px solid var(--ds-border-neutral, #dfe1e6);
    background-color: #fff;
    font-size: 0.875rem;
    padding: 8px 6px;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
      'Oxygen', 'Ubuntu', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;

    &:focus {
      border-color: var(--ds-border-focus, #4c9aff);
      outline: none;
      box-shadow: none;
    }
  }

  input[type='number'],
  input[type='weight'] {
    text-align: right;
    padding: 8px 6px;
  }

  input[type='date'] {
    padding: 8px 6px;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    margin-left: 0.5rem;
  }

  .ButtonWindow {
    background-color: #8850bf;
    &:hover {
      background: #57069e;
    }
    &:focus {
      background: #57069e;
    }
  }

  /* Estilo para ocultar as setas de incremento e decremento nos inputs */
  .form-control.no-spinners::-webkit-inner-spin-button,
  .form-control.no-spinners::-webkit-outer-spin-button {
    -webkit-appearance: none !important;
  }

  .form-control.no-spinners {
    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }

  .form-control.no-spinners[type='number'] {
    -moz-appearance: textfield !important;
    appearance: textfield !important;
  }
`;
